/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IBannerEnrichments } from '../../entities/get_banner_enrichments_v1/BannerEnrichments';

export type TGetBannerEnrichmentsModel = IModel<IGetBannerEnrichmentsRequest, TGetBannerEnrichmentsResponse>;

export interface IGetBannerEnrichmentsRequest {
  /** ID объекта **/
  objectId: number;
  /** Тип сделки **/
  dealType?: EDealType | null;
  /** Тип объекта **/
  objectType?: EObjectType | null;
}

export type TGetBannerEnrichmentsResponse = IGetBannerEnrichmentsResponse200 | IGetBannerEnrichmentsResponse400;

export interface IGetBannerEnrichmentsResponse200 extends IModelResponse<IGetBannerEnrichmentsResponse> {
  statusCode: 200;
}

export interface IGetBannerEnrichmentsResponse400 extends IModelResponse<IGetBannerEnrichmentsResponseError> {
  statusCode: 400;
}

export interface IGetBannerEnrichmentsResponse {
  /** Обогащения для баннера **/
  enrichments?: IBannerEnrichments | null;
}

export interface IGetBannerEnrichmentsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetBannerEnrichmentsResponse | IGetBannerEnrichmentsResponseError;

export enum EDealType {
  /** Продажа **/
  Sale = 'sale',
  /** Аренда **/
  Rent = 'rent',
}

export enum EObjectType {
  /** ЖК **/
  Jk = 'jk',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetBannerEnrichmentsResponse): TResponse200;
  400(response: IGetBannerEnrichmentsResponseError): TResponse400;
}

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBannerEnrichmentsModel,
  IGetBannerEnrichmentsRequest,
  IMappers,
  IGetBannerEnrichmentsResponse,
  IGetBannerEnrichmentsResponseError,
  TGetBannerEnrichmentsResponse,
} from './types';

const defaultConfig: TGetBannerEnrichmentsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'ad-banner',
  pathApi: '/v1/get-banner-enrichments/',
  hostType: 'api',
} as TGetBannerEnrichmentsModel;

function createGetBannerEnrichmentsModel(parameters: IGetBannerEnrichmentsRequest): TGetBannerEnrichmentsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBannerEnrichmentsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBannerEnrichmentsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBannerEnrichments<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBannerEnrichmentsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetBannerEnrichmentsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetBannerEnrichmentsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBannerEnrichmentsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBannerEnrichmentsResponseError);
    }
  }

  return { response, statusCode } as TGetBannerEnrichmentsResponse;
}

export { defaultConfig, createGetBannerEnrichmentsModel, fetchGetBannerEnrichments };

import { useEffect, useState } from 'react';
import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import type { IHttpApi } from '@cian/http-api/shared';
import type { ILogger } from '@cian/logger/shared';
import { fetchGetNewbuildingsInfoForTgbService } from '../../../../../../../services/adBanner';
import { IBannerEnrichments } from '../../../../../../../repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';
import { EDealType, EObjectType } from '../../../../../../../repositories/ad-banner/v1/get-banner-enrichments';

const infoForTgbMap = new Map<number, IBannerEnrichments>();

export function useNewbuidingInfoForTgb(
  { logger, httpApi }: { logger: ILogger; httpApi: IHttpApi },
  { newBuildingId, newBuildingIdIsUsed,
    objectId,
    objectType,
    dealType,
    useServerEnrichment,
  }: TTgbNativedescGeneralContext,
): null | IBannerEnrichments {
  /**
   * @description Только для значений newBuildingIdIsUsed === '1' и useServerEnrichment === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1' || useServerEnrichment === '1';

  const newBuildingIdInt = newBuildingId ? parseInt(newBuildingId, 10) : null;
  const objectIdInt = objectId ? parseInt(objectId, 10) : newBuildingIdInt;

  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = useState<null | IBannerEnrichments>(() => {
    /**
     * @description если уже много данных в кеше, то чистим его, по времени нет особого смыла как то кешировать,
     * так же как и какие данные оставлять в кеше
     */
    if (infoForTgbMap.size > 100) {
      infoForTgbMap.clear();

      return null;
    }

    return objectIdInt && isEnrichmentFromBackendEnabled ? infoForTgbMap.get(objectIdInt) ?? null : null;
  });

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && objectIdInt && !newbuidingInfoForTgb) {
      fetchGetNewbuildingsInfoForTgbService({ logger, httpApi }, {
        objectId: objectIdInt,
        objectType: objectType as EObjectType,
        dealType: dealType as EDealType,
      }).then(data => {
        if (!data.enrichments) {
          return;
        }

        infoForTgbMap.set(objectIdInt, data.enrichments);

        setNewbuidingInfoForTgb(data.enrichments);
      });
    }
    /**
     * @description изменение данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return newbuidingInfoForTgb;
}
